body {
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  text-align: center;
  color: #293c4b;
  display: flex;
  justify-content: space-around;
}

h1 {
  font-size: 30px;
  margin: 40px 70px;
}

button {
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  height: 100px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-shadow: none;
  border-radius: 0;
  font-size: 30px;
  color: #fff;
  background-color: #293c4b;
  border: none;
}
button:hover,
button.hover {
  background-color: #346392;
  text-shadow: -1px 1px #27496d;
}
button:active,
button.active {
  background-color: #27496d;
  text-shadow: -1px 1px #193047;
}

img {
  margin: 20px 0;
}

@media (max-width: 599px) {
  img {
    max-width: 250px;
  }
}

@media (min-width: 600px) {
  img {
    max-width: 300px;
  }
}
@media (min-width: 900px) {
  img {
    max-width: 450px;
  }
}
@media (min-width: 1200px) {
  img {
    max-width: 600px;
  }
}
@media (min-width: 1800px) {
  img {
    max-width: 900px;
  }
}

.footer {
  padding-top: 80px;
}
